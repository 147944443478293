import { useState } from 'react'
import { Route, Routes } from 'react-router'
import Header from '../Header/Header'
import SideMenu from '../SideMenu/SideMenu'
import Content from '../Content/Content'
import Help from '../Help/Help'

import Dashboard from '../../views/Dashboard/Dashboard'
import Calendario from '../../views/Calendario/Calendario'
import CRM from '../../views/CRM/CRM'
import Presupuestador from '../../views/Presupuestador/Presupuestador'
import Planificacion from '../../views/Planificacion/Planificacion'
import Helpdesk from '../../views/Helpdesk/Helpdesk'
import Usuarios from '../../views/Usuarios/Usuarios'

import NuevoLead from '../../views/NuevoLead/NuevoLead'
import NuevoAlumno from '../../views/NuevoAlumno/NuevoAlumno'

import HistoricoLeads from '../../views/Historico/Leads/Leads'
import HistoricoAlumnos from '../../views/Historico/Alumnos/Alumnos'
import HistoricoProfesores from '../../views/Historico/Profesores/Profesores'

import AnalisisCaptacion from '../../views/Analisis/Captacion/Captacion'
import AnalisisComportamiento from '../../views/Analisis/Comportamiento/Comportamiento'
import AnalisisLeads from '../../views/Analisis/Leads/Leads'
import AnalisisSegmentacion from '../../views/Analisis/Segmentacion/Segmentacion'
import AnalisisTelefonos from '../../views/Analisis/Telefonos/Telefonos'
import AnalisisPruebas from '../../views/Analisis/Pruebas/Pruebas'


import AlumnoFicha from '../../views/Alumno/Ficha/Ficha'
import AlumnoNotas from '../../views/Alumno/Notas/Notas'

import LeadFicha from '../../views/Lead/Ficha/Ficha'
import LeadContactos from '../../views/Lead/Contactos/Contactos'

const Layout = () => {
  const [help, setHelp] = useState(false)

  return (
    <>
      <Header onHelpClick={() => setHelp(true)} />
      <SideMenu />
      <Content>
        <Routes>
          <Route path='/' element={<Dashboard />} />

          <Route path='/calendario' element={<Calendario />} />

          <Route path='/nuevo-lead' element={<NuevoLead />} />
          <Route path='/nuevo-alumno' element={<NuevoAlumno />} />
          
          <Route path='/crm' element={<CRM />} />
          <Route path='/presupuestador' element={<Presupuestador />} />

          <Route path='/planificacion' element={<Planificacion />} />

          <Route path='/historico/leads' element={<HistoricoLeads />} />
          <Route path='/historico/alumnos' element={<HistoricoAlumnos />} />
          <Route path='/historico/profesores' element={<HistoricoProfesores />} />

          <Route path='/analisis/captacion' element={<AnalisisCaptacion />} />
          <Route path='/analisis/comportamiento' element={<AnalisisComportamiento />} />
          <Route path='/analisis/leads' element={<AnalisisLeads />} />
          <Route path='/analisis/segmentacion' element={<AnalisisSegmentacion />} />
          <Route path='/analisis/telefonos' element={<AnalisisTelefonos />} />
          <Route path='/analisis/pruebas' element={<AnalisisPruebas/>} />

          <Route path='/alumnos/:id/ficha' element={<AlumnoFicha />} />
          <Route path='/alumnos/:id/notas' element={<AlumnoNotas />} />

          <Route path='/leads/:id/ficha' element={<LeadFicha />} />
          <Route path='/leads/:id/contactos' element={<LeadContactos />} />

          <Route path='/helpdesk' element={<Helpdesk />} />
          <Route path='/usuarios' element={<Usuarios />} />
        </Routes>
      </Content>

      <Help open={help} onClose={() => setHelp(false)} />
    </>
  )
}

export default Layout
